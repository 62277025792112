import React from "react";
import Demo from "../../../page-components/Demo";
import { lineOfBusiness } from "../../../constants";

export default function DemoPage() {
  return (
    <Demo
      withLineOfBusinessOption={false}
      lineOfBusiness={lineOfBusiness.RETAIL_AND_SERVICES}
    />
  );
}
